.dash-date-picker.react-datepicker {
    border: 1px solid #101415;
    border-radius: 1em;
    color: white;
    background-color: #24282a;
    box-shadow: 0 0 0em 1em rgba(0,0,0,0.8);
}

.dash-date-picker .react-datepicker__header {
    background-color: #2e3234;
    border-bottom-color: #101415;
    border-top-left-radius: 1em;
}

.dash-date-picker .react-datepicker__day-name,
.dash-date-picker .react-datepicker__day,
.dash-date-picker .react-datepicker__time-name {
    color: white;
}

.react-datepicker__day--disabled {
    color: #3a3f41 !important;
}

.dash-date-picker .react-datepicker__current-month,
.dash-date-picker .react-datepicker-time__header,
.dash-date-picker .react-datepicker-year-header {
    color: white;
}

.dash-date-picker .react-datepicker__time-container
.react-datepicker__time {
    background-color: #24282a;
}

.dash-date-picker .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 1em;
}

.dash-date-picker .react-datepicker__time-container {
    border-left-color: #101415;
}

.dash-date-picker .react-datepicker__day--in-selecting-range,
.dash-date-picker .react-datepicker__day--in-range {
    border-radius: 0.25em;
    background-color: #11656A !important;
    color: black;
}

.dash-date-picker .react-datepicker__day--selected,
.dash-date-picker .react-datepicker__day--range-end {
    border-radius: 0.25em;
    background-color: #1DB1BA !important;
    color: black;
}

.dash-date-picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #1DB1BA;
    color: black;
}

.dash-date-picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: auto;
    padding: 8px;
}

.dash-date-picker .react-datepicker__day:hover,
.dash-date-picker .react-datepicker__month-text:hover,
.dash-date-picker .react-datepicker__quarter-text:hover,
.dash-date-picker .react-datepicker__year-text:hover {
    border-radius: 0.25em;
    background-color: #2E3234;
}

.dash-date-picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background-color: #2E3234;
}

.dash-date-picker .react-datepicker__day--selected:hover,
.dash-date-picker .react-datepicker__day--in-selecting-range:hover,
.dash-date-picker .react-datepicker__day--in-range:hover,
.dash-date-picker .react-datepicker__month-text--selected:hover,
.dash-date-picker .react-datepicker__month-text--in-selecting-range:hover,
.dash-date-picker .react-datepicker__month-text--in-range:hover,
.dash-date-picker .react-datepicker__quarter-text--selected:hover,
.dash-date-picker .react-datepicker__quarter-text--in-selecting-range:hover,
.dash-date-picker .react-datepicker__quarter-text--in-range:hover,
.dash-date-picker .react-datepicker__year-text--selected:hover,
.dash-date-picker .react-datepicker__year-text--in-selecting-range:hover,
.dash-date-picker .react-datepicker__year-text--in-range:hover {
    background-color: #1DB1BA;
}

.dash-date-picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #1DB1BA;
}

.dash-date-picker .react-datepicker__day--keyboard-selected,
.dash-date-picker .react-datepicker__month-text--keyboard-selected,
.dash-date-picker .react-datepicker__quarter-text--keyboard-selected,
.dash-date-picker .react-datepicker__year-text--keyboard-selected {
    background-color: transparent;
    color: white;
}

.dash-date-picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    border-bottom-right-radius: 1em;
}

.dash-date-picker .react-datepicker--time-only .react-datepicker__time,
.dash-date-picker .react-datepicker--time-only .react-datepicker__time-box {
    border-bottom-left-radius: 1em !important;
    border-bottom-right-radius: 1em;
}

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
    border-bottom-left-radius: 1em !important;
}