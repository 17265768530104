[type=range] {
	-webkit-appearance: none;
	background: transparent;
	width: 100%
}

[type=range]::-moz-focus-outer {
	border: 0
}

[type=range]:focus {
	outline: 0
}

[type=range]:focus::-webkit-slider-runnable-track {
	background: #3a3f42
}

[type=range]:focus::-ms-fill-lower {
	background: #2e3234
}

[type=range]:focus::-ms-fill-upper {
	background: #3a3f42
}

[type=range]::-webkit-slider-runnable-track {
	cursor: default;
	height: 8px;
	-webkit-transition: all .2s ease;
	transition: all .2s ease;
	width: 100%;
	box-shadow: none;
	background: #2e3234;
	border: 1px solid #111;
	border-radius: 5px
}

[type=range]::-webkit-slider-thumb {
	box-shadow: none;
	background: #1DB1BA;
	border: 1px solid #111;
	border-radius: 10em;
	cursor: default;
	height: 16px;
	width: 16px;
	-webkit-appearance: none;
	margin-top: -5px
}

[type=range]::-moz-range-track {
	height: 8px;
	-moz-transition: all .2s ease;
	transition: all .2s ease;
	width: 100%;
	background: #2e3234;
	border: 1px solid #111;
	border-radius: 5px;
	height: 4px
}

[type=range]::-moz-range-thumb,
[type=range]::-moz-range-track {
	box-shadow: none;
	cursor: default
}

[type=range]::-moz-range-thumb {
	background: #1DB1BA;
	border: 1px solid #111;
	border-radius: 12px;
	height: 20px;
	width: 20px
}

[type=range]::-ms-track {
	cursor: default;
	height: 8px;
	-ms-transition: all .2s ease;
	transition: all .2s ease;
	width: 100%;
	background: transparent;
	border-color: transparent;
	border-width: 12px 0;
	color: transparent
}

[type=range]::-ms-fill-lower {
	background: #222526
}

[type=range]::-ms-fill-lower,
[type=range]::-ms-fill-upper {
	box-shadow: none;
	border: 1px solid #111;
	border-radius: 10px
}

[type=range]::-ms-fill-upper {
	background: #2e3234
}

[type=range]::-ms-thumb {
	box-shadow: none;
	background: #1DB1BA;
	border: 1px solid #111;
	border-radius: 12px;
	cursor: default;
	height: 20px;
	width: 20px;
	margin-top: 2px
}

[type=range]:disabled::-webkit-slider-thumb,
[type=range]:disabled::-moz-range-thumb,
[type=range]:disabled::-ms-thumb {
	cursor: not-allowed;
	background: #2b2b2b
}

[type=range]:disabled::-webkit-slider-runnable-track,
[type=range]:disabled::-ms-fill-lower,
[type=range]:disabled::-ms-fill-upper {
	cursor: not-allowed
}
