/* Customize website's scrollbar like Mac OS */

.hide-scrollbar {
    scrollbar-width: none;
}

.scrollable {
    overflow-y: scroll;
    scrollbar-color: #A8ACAD transparent;
}

/* total width */
.scrollable::-webkit-scrollbar {
    background-color: transparent;
    width: 1em;
}

/* background of the scrollbar except button or resizer */
.scrollable::-webkit-scrollbar-track {
    background-color: transparent;
}

/* scrollbar itself */
.scrollable::-webkit-scrollbar-thumb {
    cursor: pointer;
    min-height: 2em;
    background-color: transparent;
    box-shadow: inset 0 0 0 10em #A8ACAD;
    border-radius: 1em;
    border: 0.4em solid transparent;
}
.scrollable::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 0 10em white;
}

.scrollable::-webkit-scrollbar-track:hover {
    background-color: transparent;
}

/* set button(top and bottom of the scrollbar) */
.scrollable::-webkit-scrollbar-button {
    display: none;
}

.scrollable::-webkit-scrollbar-corner {
    background: transparent;
}

.scrollable-border::-webkit-scrollbar:horizontal {
    border-top: #2E3234 1px solid;
}

.scrollable-border::-webkit-scrollbar:vertical {
    border-left: #2E3234 1px solid;
}
