.markdown {
    color: white;
    line-height: 1.5
}

.markdown strong {
    color: white;
}

.markdown h1, .markdown h2, .markdown h3, .markdown h4, .markdown h5 {
    color: white;
    font-size: 1.2em;
}

.markdown li {
    margin: 0.5em;
}