@import './keyframes.css';

.rotate {
	-webkit-animation: rotate 1s linear infinite;
	animation: rotate 1s linear infinite
}

#bars {
	height: 1.875em;
	left: 50%;
	position: absolute;
	top: 50%;
	width: 2.25em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	transform: translate3d(-50%, -50%, 0)
}

.bar {
	-webkit-animation: sound 0ms linear -.8s infinite alternate;
	animation: sound 0ms linear -.8s infinite alternate;
	transform: translateY(-50%);
	height: .1875em;
	width: .1875em;
	position: relative;
	top: 50%
}

.bar:first-child {
	-webkit-animation-duration: 474ms;
	animation-duration: 474ms
}

.bar:nth-child(2) {
	-webkit-animation-duration: 433ms;
	animation-duration: 433ms
}

.bar:nth-child(3) {
	-webkit-animation-duration: 407ms;
	animation-duration: 407ms
}

.bar:nth-child(4) {
	-webkit-animation-duration: 458ms;
	animation-duration: 458ms
}

.bar:nth-child(5) {
	-webkit-animation-duration: .4s;
	animation-duration: .4s
}

.bar:nth-child(6) {
	-webkit-animation-duration: 427ms;
	animation-duration: 427ms
}

.bar:nth-child(7) {
	-webkit-animation-duration: 441ms;
	animation-duration: 441ms
}

.bar:nth-child(8) {
	-webkit-animation-duration: 419ms;
	animation-duration: 419ms
}

.bar:nth-child(9) {
	-webkit-animation-duration: 487ms;
	animation-duration: 487ms
}

.bar:nth-child(10) {
	-webkit-animation-duration: 442ms;
	animation-duration: 442ms
}

.pulse {
    transform: translate3d(-50%, -50%, 0) scale(1);
	animation: pulse-black 2s infinite;
    box-shadow: 0 0 0 0 rgba(27, 177, 186, 1);
}

@keyframes pulse-black {
	0% {
		transform: translate3d(-50%, -50%, 0) scale(0.90);
		box-shadow: 0 0 0 0 rgba(27, 177, 186, 1);
	}

	70% {
		transform: translate3d(-50%, -50%, 0) scale(1);
		box-shadow: 0 0 0 10px rgba(27, 177, 186, 0);
	}

	100% {
		transform: translate3d(-50%, -50%, 0) scale(0.90);
		box-shadow: 0 0 0 0 rgba(27, 177, 186, 0);
	}
}

@keyframes bounceScale {
	0%, 100% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.5);
	}
	40% {
		transform: scale(0.9);
	}
	45% {
		transform: scale(1.1);
	}
	48% {
		transform: scale(0.97);
	}
	50% {
		transform: scale(1);
	}
}