@-webkit-keyframes sound {
	0% {
		opacity: .35;
		height: .1875em
	}
	to {
		opacity: 1;
		height: 1.75em
	}
}

@keyframes sound {
	0% {
		opacity: .35;
		height: .1875em
	}
	to {
		opacity: 1;
		height: 1.75em
	}
}

@-webkit-keyframes rotate {
	0% {
		transform: rotate(0deg)
	}
	to {
		transform: rotate(-1turn)
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg)
	}
	to {
		transform: rotate(-1turn)
	}
}
