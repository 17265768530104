@import './animation.css';
@import './grid.css';
@import './date-picker.css';
@import './range.css';
@import './table.css';
@import './scroll.css';
@import './markdown.css';
@import './zoom.css';

body,
button,
input {
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 1em
}

svg {
	max-width: 100%
}

ul {
	padding-left: 1.25em
}

a, a:visited {
    color: #4dd9e0;
}

.plain-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 100%;
	color: #fff;
	font: inherit;
	font-size: 80%;
    height: calc(2rem - 2px);
	margin: 0;
	padding: 0;
	background: transparent;
	border: none;
	outline: none;
	transition: color .5s ease;
}

.plain-input option {
    background-color: #C2BBBA;
    color: black;
}

input[type="file"] {
    display: none;
}

input::-webkit-input-placeholder {
    font-size: '100%';
    color: 'white';
}

.yellow::-webkit-input-placeholder {
	color: #E0CF02
}

.plain-input[disabled] {
	color: #BFC3C5;
	opacity: 1;
	cursor: not-allowed
}

.padded-input {
	padding: .5em .75em
}

.mic,
.mic * {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.mic * {
	pointer-events: none
}
