#grid-wrapper {
    display: grid;
    grid-template: auto / auto 1fr;
}

#grid-main {
    display: grid;
    grid-template: auto 1fr auto / auto;
    padding-right: 1.5em;
}

#grid-content {
    display: grid;
    grid-template: auto 1fr auto / auto;
}

#grid-stage {
    order: 1
}

#portal-sidebar {
    order: 2
}

#grid-stage {
    display: grid;
    grid-template: auto 1fr / auto;
}

@media (min-width: 1000px) {
    #grid-content {
        grid-template: auto / auto 1fr auto;
    }
    #portal-sidebar {
        order: 1
    }
    #grid-stage {
        order: 2
    }
    #portal-notifications {
        order: 3
    }
}
