table {
	color: #fff;
	width: 100%;
    border-spacing: 0;
}

th, td {
    padding: 0;
    text-align: left
}
